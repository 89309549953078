import {useEffect, useState, useMemo} from 'react'
import { patientService } from '../../services/patient.services'
import { useForm, useWatch } from 'react-hook-form'
import { helpFunc } from '../../helpers/helpFunction'

const DEFAULT_FORM_VALUES = {
        has_risk_diagnosis: false,
        diagnoses: [
            {
                label: "Vyhledejte...", value: "",
                drugs: [{label: 'Vyhledejte...', value: ''}]
            }
        ],
        drugs : [
            {label: "Vyhledejte...", value: ""}
        ],
        note: "", 
        tags: [], 
}

export const RiskMedicalAnamnesisLogic = ( riskDrugHistory = {}, checkin = {}, careID, careIsLocked = false) => {
    const [ state, setState ] = useState({loading: true, popUp: false, edit: false, formActive: true, checkinInfo: false, submit: false, saveData: false})
    const [ error, setError ] = useState("")
    const [ table, setTable ] = useState ({loading: true})
    const [ tags, setTags ] = useState ({loading: true, data: null})
    const [ comments, setComments ] = useState({loading: true})

    //Load component data on mount
    useEffect(() => {
        //setting checkin data, patient doesn't have to have checkin in all cases
        getCheckinData (checkin)
        //If patient already have risk medical anamnesis load it form db and populate form
        if (riskDrugHistory != null) {
            const id = riskDrugHistory.id
            setFormValues (riskDrugHistory)
            getComments (id)
        }
        else if (careIsLocked) {
            deactivateForm ()   
        }
        else {
            //load select options for tags
            getTags()
        }
    }, [])

    //deactivate form when care is locked 
    const deactivateForm = () => {
        setState ({...state, formActive: false, loading: false})
    }
    
    //Getting table data -> patient checkin data
    const getCheckinData = async (checkin) => {
        if (checkin) {
            try {
                
                setTable ({...table, loading: true})
                //parse data from checkin props
                const data = await helpFunc.pCheckinData(checkin)
                setTable ({...table, loading: false, data: data})
            }
            catch (error) {
                console.error(error)
                setError({error: true, message: 'Chyba při načítání pacientovi vstupní kontroly.'})
            }
        }
        else {
            setTable({...table, loading: false, data: null})
        }
    }

    //Load tags options from db 
    const getTags = async () => {
        try {
            setState({...state, loading: true})
            let data = await patientService.getTags()
            data = await helpFunc.pDataForSelect(data.results, 'tags') 
            setState({...state, loading: false})
            setTags({...tags, data: data})
        }
        catch (error) {
            console.error(error)
            setError({error: true, message: 'Chyba při načítání štítků.'})
        }
    }

    //FORM SECTION
    //Populating form with risk drug history data
    const setFormValues = async (data) => {
        setState ({...state, loading: true})
        formMethods.setValue('has_risk_diagnosis', data.has_risk_diagnosis)
        formMethods.setValue('diagnoses', await helpFunc.pDataForSelect (data.diagnoses, 'diagnosis'))
        formMethods.setValue('drugs', await helpFunc.pDataForSelect (data.drugs, 'drugs'))
        formMethods.setValue('tags', await helpFunc.pDataForSelect(data.tags, 'tags'))
        formMethods.setValue('note', data.note)
        setState ({...state, loading: false, formActive: false, edit: true})
    }

    //parsing defalut values for form => different parsing for different
    const formMethods = useForm(
        {
            defaultValues: DEFAULT_FORM_VALUES
        }
    )

    // const isDirty = formMethods.formState.isDirty

    // useEffect (() => {
    //     console.log(isDirty)
    //     console.log(formMethods.getValues())
    //     console.log(DEFAULT_FORM_VALUES)
    //     console.log(formMethods.getValues () === DEFAULT_FORM_VALUES)
    //     formMethods.setValue('has_risk_diagnosis', true)

    // },[isDirty])

    //COMMENT SECTION
    const commentsMethods = useForm()

    //Load comments from db
    const getComments = async (id) => {
        try {
            setComments({...comments, loading: true})
            //get comment data from db
            let data = await patientService.getRiskDrugHistoryComments(id)
            //replace null from data 
            data = helpFunc.replaceNull(data)
            //parse comments data as a list of comments
            const list = await helpFunc.pComments (data, 'drugHistory', careIsLocked)
            setComments({...comments, loading: false, list: list})
        }
        catch (error) {
            console.error(error)
            setError({error: true, message: 'Chyba při načítání komentářů'})
        }
    }
    
    //HANDELING STATE
    //User Click edit btn 
    const editForm = async (e) => {
        //no page reload
        e.preventDefault()
        //get tags for select
        if (formMethods.getValues('has_risk_diagnosis')) {
            await getTags ()
            setState({...state, formActive: true, loading: false, dataSaved: false})
        }
        else {
            formMethods.setValue('has_risk_diagnosis', true)
            await getTags ()
            setState({...state, formActive: true, loading: false, dataSaved: false})
        }
    }

    // Saving or updating data in risk drugs history
    const saveData = async (data, e) => {
        //no page reload
        e.preventDefault()
        //extract diagnose data from posted data
        let diagnose = data.diagnoses
        //delete diangoses data from posted data
        delete data.diagnoses
        //parse form data (without diagnoses) to post
        data = {...data, care: careID, drugs: helpFunc.objToArrApi(data.drugs) }

        try {
            const pTags = await helpFunc.createAndParseTags(helpFunc.objToArrApi(data.tags));
            data = { ...data, tags: pTags };
        } catch(error) {
            console.error(error);
            setError ({error:true, message: 'Štítek již existuje', popUp: true});
            return Promise.reject(error);
        }
        

        //post data
        try {
            //if user edit form => patching data
            if (state.edit) {
                //patch data without diagnosis
                await patientService.patchRiskDrugHistory(data, riskDrugHistory.id)
                //patch diagnoses -> delete existing one and post new one
                await patientService.patchDiagnoses(data.has_risk_diagnosis ? diagnose : [],'risk-drug-history-diagnosis', riskDrugHistory.diagnoses, riskDrugHistory.id)
            }
            //if user saving a new form => post data 
            else {
                const res = await patientService.postRiskDrugHistory(data)
                await patientService.postDiagnoses(diagnose,'risk-drug-history-diagnosis', res.data.id)
            }
            setState({...state, formActive: false, submit: false, dataSaved: true})
            window.location.reload(false);  
        }
        catch (error) {
            console.error(error)
            setError ({error:true, message: 'Chyba při ukládání dat.', popUp: true})
        }
    }

    //clear rla form 
    const clearForm = (state) => {
        if (state) {
            formMethods.reset(DEFAULT_FORM_VALUES)
        }
    }

    //Saving user comments
    const saveComment = async (data) => {
        //clear errors from form state
        commentsMethods.clearErrors()
        //add risk drug history id
        data = {...data, risk_drug_history: riskDrugHistory.id}
        setState({...state, submit: true, commentSaved: false})
        try {
            //Saving new comment
            await patientService.postRiskDrugHistoryComment(data)
            setState({...state, submit: false, commentSaved: true})
        }
        catch (error) {
            console.error(error) 
            setError({error: true, message: 'Chyba při ukládíní komentáře.', popUp: true})
        }
        //get fresh comment data from db
        await getComments (riskDrugHistory.id)
        commentsMethods.setValue('text', "")
    }
    
    //Toggle checkin when user clicks arrow down btn
    const showCheckin = () => {
        setState({...state, checkinInfo: !state.checkinInfo})
    }

    //HANDELING COMMENTS ERROR POPUP 
    const togglePopUp = () => {
        setError({error: false, popUp: false})
    }

    //TABLE SECTION & Logic, Columns
    const columns = useMemo ( () => [
        { Header: "Výsledky vstupní kontroly", columns: [
            {
                Header: "Polypragmazie", accessor: "polypharmacy",
            },
            {
                Header: "Úzké ter. okno", accessor: "narrow_therapeutic_window",
            },
            {
                Header: "Interakční potenciál", accessor: "high_interaction_potential",
            },  
            {
                Header: "Systémová imunosupresiva", accessor: "systemic_corticosteroids",
            },
            {
                Header: "Riziková diagnóza", accessor: "toggle_diagnoses",
            },
            {
                Header: "Renální insuficience", accessor: "renal_insufficiency",
            },
            {
                Header: "Hepatální insuficience", accessor: "hepatic_insufficiency",
            },
            {
                Header: "Změny HEM/BIO", accessor: "significant_biochemical_changes",
            },
            {
                Header: "Intenzivní péče", accessor: "intensive_care",
            },
            {
                Header: "Nutný zásah KF", accessor: "pharmacist_intervention_required",
            },
            {
                Header: "Vyžádané konzilium", accessor: "consultation_requested",
            },
        ]}
    ])

    return {state, error, columns, table, formMethods, comments, commentsMethods, tags, 
        saveData, editForm, saveComment, showCheckin, togglePopUp, clearForm} 
}
