import { authHeader } from "../auth/authHeader";
import axios from "axios";

const getReportsOptions = async () => {
  const options = authHeader("GET");
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/reports",
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return Promise.reject(error);
  }
};

const generateReport = async (name, params) => {
  let options = authHeader("GET");
  let queryParams = new URLSearchParams(params);
  if (params.care_type === "ambulation") {
    queryParams.append("care_type", "external");
  }
  try {
    // const response = await axios.get(process.env.REACT_APP_ROOT_API + `/reports/${name}/generate/`, {...options, params: queryParams})
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/reports/${name}/generate-async/`,
      { ...options, params: queryParams }
    );
    return response.data.task_id;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getReportStatus = async (id) => {
  let options = authHeader("GET");
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/tasks/${id}`,
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const printRecommendation = async (id, type) => {
  let options = authHeader("GET");
  let params = { recommendation: id, recommendation_type: type };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/reports/recommendation/generate/",
      { ...options, params: params }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const printPlanEvaluation = async (id) => {
  let options = authHeader("GET");
  let params = { evaluation: id };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        "/reports/pharmacological_plan_evaluation/generate/",
      { ...options, params: params }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLinks = async () => {
  let options = authHeader("GET");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ROOT_API}/links/`,
      { ...options }
    );

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const others = {
  getReportsOptions,
  generateReport,
  getReportStatus,
  printRecommendation,
  printPlanEvaluation,
  getLinks,
};
