import React from 'react'
import { ControlSelect } from '..'

export const UserSettingsForm = ({title, label, name, options, ...props}) => {

    return (
        <>
        <h1>{title}</h1>
            <div className='form-row'>
                <div className = "form-column">
                    <ControlSelect options={options} label={label + ' 1'} name={`${name}_0`} />
                    <ControlSelect options={options} label={label + ' 2'} name={`${name}_1`} />
                    <ControlSelect options={options} label={label + ' 3'} name={`${name}_2`} />
                </div>  
                <div className='form-column'>   
                    <ControlSelect options={options} label={label + ' 4'} name={`${name}_3`} />
                    <ControlSelect options={options} label={label + ' 5'} name={`${name}_4`} />
                    <ControlSelect options={options} label={label + ' 6'} name={`${name}_5`} />
                </div> 
            </div>
        </>
    )
} 
  