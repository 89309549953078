import React from "react";
import DOMPurify from "dompurify";
import { authHeader } from "../auth/authHeader";
import axios from "axios";
import { Comment, TextContainer } from "../components";
import { patientService } from "../services/patient.services";
import uuid from "react-uuid";

const countBmi = (weight, height) => {
  try {
    const bmi = ((weight / (height * height)) * 10000).toFixed(2);
    return bmi;
  } catch (err) {
    return "Vyskytla se chyba. Doplňte správné údaje o pacientovi.";
  }
};

const countPatientAge = (age_display, age_display_measure_unit) => {
  if (age_display_measure_unit === "month") return `${age_display} M`;
  return age_display;
};

const countPatientAgeFromMounth = (ageInMounth) =>
  ageInMounth < 24 ? `${ageInMounth} M` : (ageInMounth / 12).toFixed(0);

const countDaysInHospital = (acceptDate) => {
  const currDate = new Date();
  const startDate = new Date(acceptDate);
  const oneDay = 1000 * 60 * 60 * 24;
  const difInTime = currDate.getTime() - startDate.getTime();

  return Math.round(difInTime / oneDay);
};

const formatDate = (data) => {
  if (data === "") return "Žádné datum";
  const date = new Date(data);
  const [month, day, year] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];
  return `${day}.${month}.${year}`;
};

const formatDateWithTime = (data) => {
  if (data === "") return "No date";
  const date = new Date(data);
  let [month, day, year, hours, minutes, seconds] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  month = month < 10 ? `0${month}` : `${month}`;
  day = day < 10 ? `0${day}` : day;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

//Return string format date
const formatDateAsync = async (data) => {
  if (data === "") return "No date";
  const date = new Date(data);
  const [month, day, year] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];
  return `${day}.${month}.${year}`;
};

const parseDate = (data) => {
  if (!data) {
    return null;
  }
  const date = new Date(data);
  let [month, day, year] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
};

//Get current date in str format
const currentDate = () => {
  return formatDate(new Date());
};

//get current date in date format
const getCurrentDate = () => {
  return new Date();
};

//return date X days from now
const getPriorDate = (days = 0) => {
  const today = new Date();
  return new Date(new Date().setDate(today.getDate() - days));
};

const pStrToNum = (item) => {
  return parseInt(item);
};

const pNumToStr = (num) => {
  return num.toString();
};

//Sort clinics alphabeticaly
const sortClinics = (data) => {
  return data.sort(compareClinics);
};

const compareClinics = (a, b) => {
  if (a.abbreviation.toLowerCase() < b.abbreviation.toLowerCase()) {
    return -1;
  }
  if (a.abbreviation.toLowerCase() > b.abbreviation.toLowerCase()) {
    return 1;
  }
  return 0;
};

//Parsing data for API
const objToArrApi = (data = []) => {
  if (data === null || data === undefined) return [];
  if (Object.entries(data).length === 0) return [];
  return data
    .filter((item) => item != null && item.value != "")
    .map((item) => item.value);
};

const pDataForSelect = async (data = [], name = "") => {
  if (data === null || data === undefined) return "";
  switch (name) {
    case "drug":
    case "drugs":
    case "narrow_therapeutic_window_drugs":
    case "high_interaction_potential_drugs":
      return parseDataForDrugsSelect(data);
    case "external_department":
      return parseDataForExternalDepartmentsSelect(data);
    case "external_department_extend":
      return parseDataForExternalDepartmentsWithDepartmentSelect(data);
    case "diagnosis":
      return parseDataForDiagnose(data);
    case "historyFilter":
      return parseOptionsForHistoryFilter(data);
    case "clinics":
      return parseClinicsForSelect(data);
    case "reports":
      return parseReportsForSelect(data);
    case "tags":
      return parseDataForSelect(data);
    default:
      return parseDataForSelect(data);
  }
};

const parseDataForSelect = async (data = []) => {
  const options = data.map((item) => {
    return { label: item.name, value: item.id };
  });
  return options;
};

const parseReportsForSelect = async (data = []) => {
  const options = data.map((item) => {
    return { label: item.description, value: item.name };
  });
  return options;
};

const parseClinicsForSelect = async (data = []) => {
  const options = data.map((item) => {
    return { label: item.description, value: item.id };
  });
  return options;
};

const parseDataForExternalDepartmentsSelect = async (data = []) => {
  const options = data.map((item) => {
    return { label: item.actual_organization, value: item.id };
  });
  return options;
};

const parseDataForExternalDepartmentsWithDepartmentSelect = async (
  data = []
) => {
  const options = data.map((item) => {
    return {
      label: item.actual_organization + " " + item.department,
      value: item.id,
    };
  });
  return options;
};

const parseDataForDrugsSelect = async (data = []) => {
  if (data.length === 0) return [{ label: "Vyhledejte...", value: "" }];
  else
    return data.map((item) => {
      return {
        label: `${item.name} | ${item.name_supplement}`,
        value: item.id,
      };
    });
};

const parseDataForDrugsSelectNoSync = (data = []) => {
  if (!data.length) return [{ label: "Vyhledejte...", value: "" }];
  else
    return data.map((item) => {
      return {
        label: `${item.name} | ${item.name_supplement}`,
        value: item.id,
      };
    });
};

const parseDataForDiagnose = (data = []) => {
  if (!data.length)
    return [
      {
        label: "Vyhledejte...",
        value: "",
        drugs: [{ label: "Vyhledejte...", value: "" }],
      },
    ];
  else {
    return data.map((item) => {
      return {
        label: `${item.diagnosis.code} | ${item.diagnosis.name}`,
        value: item.diagnosis.id,
        id: item.id,
        drugs: parseDataForDrugsSelectNoSync(item.drugs),
      };
    });
  }
};

const parseBoolToStr = (bool = null) => {
  return bool === true ? "Ano" : "Ne";
};

const drugAvailable = (drugArr = []) => {
  return drugArr.length === 0 ? "Ne" : "Ano";
};

const parseOptionsForHistoryFilter = (arr) => {
  const options = arr.map((item) => {
    return { label: item, value: item };
  });

  return options;
};

const replaceNull = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = "";
    }
  });
  return obj;
};

const parseNameToStringHistory = (name) => {
  let str;
  switch (name) {
    case "RiskDrugHistory":
      str = "Riziková léková anamnéza";
      break;
    case "CheckIn":
      str = "Vstupní kontrola";
      break;
    case "PharmacologicalEvaluation":
      str = "FTD Hodnocení";
      break;
    case "PatientInformation":
      str = "Informace o pacientovi";
      break;
    case "PharmacologicalPlan":
      str = "Farmakologický plán";
      break;
    case "PharmacologicalPlanVerification":
      str = "Aktualizace farmakologického plánu";
      break;
    case "Recommendation":
      str = "Farmakoterapeutické doporučení";
      break;
    case "DischargeRecommendation":
      str = "Farmakoterapeutické doporučení do propouštěcí zprávy";
      break;
    case "Patient":
      str = "Údaje o pacientovi";
      break;
    default:
      str = "Nepodařilo se najít odpovídající sekci.";
      break;
  }
  return str;
};

//REPORTS OPTIONS HELPER
const getTimeOptions = () => {
  let currYear, months;
  let years = [];
  months = [
    { value: 1, label: "Leden" },
    { value: 2, label: "Únor" },
    { value: 3, label: "Březen" },
    { value: 4, label: "Duben" },
    { value: 5, label: "Květen" },
    { value: 6, label: "Červen" },
    { value: 7, label: "Červenec" },
    { value: 8, label: "Srpen" },
    { value: 9, label: "Září" },
    { value: 10, label: "Říjen" },
    { value: 11, label: "Listopad" },
    { value: 12, label: "Prosinec" },
  ];
  currYear = new Date();
  currYear = currYear.getFullYear();

  for (let i = currYear; i >= 2000; i--) {
    years.push(i);
  }
  years = years.map((year) => {
    return { label: year.toString(), value: year };
  });

  return { years, months };
};

//PARSE DIAGNOSES FOR API
const parseDiagnosesForApi = async (data, req, endPoint, checkinID) => {
  let requests = [];
  const options = authHeader(req);
  const url = process.env.REACT_APP_ROOT_API;
  data.forEach((diagnose) => {
    diagnose = {
      ...diagnose,
      check_in: checkinID,
      drugs: objToArrApi(diagnose.drugs),
    };
    requests.push(axios.post(`${url}/${endPoint}/`, diagnose, { ...options }));
  });

  return data;
};

//Parse data for checkin table, change to bools to str
const pCheckinData = async (data) => {
  //change boolian values to string (false, true => 'no', 'yes')
  return [
    {
      ...data,
      high_interaction_potential: helpFunc.parseBoolToStr(
        data.high_interaction_potential
      ),
      toggle_diagnoses: helpFunc.parseBoolToStr(data.toggle_diagnoses),
      polypharmacy: helpFunc.parseBoolToStr(data.polypharmacy),
      renal_insufficiency: helpFunc.parseBoolToStr(data.renal_insufficiency),
      hepatic_insufficiency: helpFunc.parseBoolToStr(
        data.hepatic_insufficiency
      ),
      narrow_therapeutic_window: helpFunc.parseBoolToStr(
        data.narrow_therapeutic_window
      ),
      significant_biochemical_changes: helpFunc.parseBoolToStr(
        data.significant_biochemical_changes
      ),
      systemic_corticosteroids: helpFunc.parseBoolToStr(
        data.systemic_corticosteroids
      ),
      intensive_care: helpFunc.parseBoolToStr(data.intensive_care),
      pharmacist_intervention_required: helpFunc.parseBoolToStr(
        data.pharmacist_intervention_required
      ),
      consultation_requested: helpFunc.parseBoolToStr(
        data.consultation_requested
      ),
    },
  ];
};

//Parse patient data
const pPatientData = async (data) => {
  const birth_date = helpFunc.formatDate(data.birth_date);
  const bmi = helpFunc.countBmi(data.weight, data.height);
  const days = data.current_care_days;
  const last_dekurz = data?.current_care?.last_dekurz
    ? helpFunc.formatDate(data?.current_care?.last_dekurz?.made_at)
    : "Chybí data";

  return {
    ...data,
    bmi: bmi,
    days: days,
    last_dekurz: last_dekurz,
    birth_date: birth_date,
  };
};

//Parse doctors name
const pDoctorName = (data) => {
  return `${data?.f_title} ${data?.name} ${data?.l_title}`;
};

//Parse doctors name
const pDoctorNameAsync = async (data) => {
  return `${data?.f_title} ${data?.name} ${data?.l_title}`;
};

//parse data for comments
//Get list of commonets components with comments text, user and other data
const pComments = async (data, type = "", careIsLocked = false) => {
  return data.map((comment) => {
    return (
      <Comment
        key={comment.id}
        id={comment.id}
        name={comment.author}
        type={type}
        text={comment.text}
        date={helpFunc.formatDate(comment.created_at)}
        careIsLocked={careIsLocked}
      />
    );
  });
};

//parse data for comments
//Get list of getlist of parsed and styled text containers with comments text, user and other data
const pTextContainer = (
  data,
  name,
  careIsLocked = false,
  onDeletePlan,
  handleReloadProcedures
) => {
  return data.map((value) => {
    return (
      value && (
        <TextContainer
          key={uuid()}
          rawData={value}
          type={value.type}
          name={name}
          id={value.id}
          careIsLocked={careIsLocked}
          onDeletePlan={onDeletePlan}
          handleReloadProcedures={handleReloadProcedures}
          printBtn={
            (value.type === "recommendationDischarge" ||
              value.type === "evaluation" ||
              value.type === "recommendation") &&
            true
          }
        />
      )
    );
  });
};

//Get patient information from active care
const getActiveCare = (data) => {
  return data.filter((val) => val.is_active === true);
};

//Get patient information from active care
const getActiveCareAsync = async (data) => {
  return data.filter((val) => val.is_active === true);
};

// function create new tags and return array of tag's id
const createAndParseTags = async (tags) => {
  try {
    const tagsToCreate = tags.filter(
      (tag) => typeof tag === "string" || tag instanceof String
    );

    await Promise.all(
      tagsToCreate.map(async (tag) => {
        const id = await patientService.postTags({
          name: tag,
          description: tag,
        });
        tags.push(id);
      })
    );

    const tagsIds = tags.filter((tag) => typeof tag == "number");
    return tagsIds;
  } catch (e) {
    e.message = "Štítek již existuje";
    return Promise.reject(e);
  }
};

//make text shorter according to number of chars
const cutText = (text = "", num) => {
  return text.slice(0, num - 1);
};

//convert hmtl tags to formated text in a save way
const createHTMLMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const removeHiddenChar = (text) => {
  return text
    .replace(/&nbsp;/g, "")
    .replace(/&amp;nbsp;/g, " ")
    .replace(/nbsp;/g, " ")
    .replace(/&amp;amp;/g, " ");
};

//strip text of html tags
const removeHTMLTags = (text = "") => {
  if (!text) return text;
  else text = text.toString();
  return text
    .replace(/<\/p\s*[\/]?>/gi, "\n")
    .replace(/\u00a0/g, " ")
    .replace(/(<([^>]+)>)/gi, "");
};

const removeHTMLTagsWithoutNewLine = (text = "") => {
  if (!text) return text;
  else text = text.toString();
  return text
    .replace(/<\/p\s*[\/]?>/gi, "")
    .replace(/\u00a0/g, " ")
    .replace(/(<([^>]+)>)/gi, "");
};

//find a record author
const getAuthor = (author = {}) => {
  if (author?.person) {
    return `${author.person?.f_title} ${author.person?.name} ${author.person?.l_title}`;
  } else if (author?.username) {
    return `${author?.username} ${author?.first_name} ${author?.last_name}`;
  } else {
    return `Žádný uživatel`;
  }
};

const getPatientDepartment = (patient) => {
  if (patient.current_care.last_dekurz !== null) {
    return patient?.current_care?.last_dekurz?.department.description;
  }

  return patient?.current_care?.department?.description;
};

export const helpFunc = {
  countBmi,
  countPatientAge,
  countPatientAgeFromMounth,
  countDaysInHospital,
  formatDate,
  formatDateWithTime,
  formatDateAsync,
  parseDate,
  currentDate,
  getCurrentDate,
  getPriorDate,
  pStrToNum,
  pNumToStr,
  sortClinics,
  objToArrApi,
  parseBoolToStr,
  drugAvailable,
  pDataForSelect,
  parseClinicsForSelect,
  parseNameToStringHistory,
  getTimeOptions,
  replaceNull,
  parseDiagnosesForApi,
  pCheckinData,
  pPatientData,
  pDoctorName,
  pDoctorNameAsync,
  pComments,
  pTextContainer,
  getActiveCare,
  getActiveCareAsync,
  createAndParseTags,
  cutText,
  createHTMLMarkup,
  removeHTMLTags,
  removeHTMLTagsWithoutNewLine,
  removeHiddenChar,
  getAuthor,
  getPatientDepartment,
  parseDataForExternalDepartmentsWithDepartmentSelect,
};
