import React from "react";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { PatientProfileLogic } from "../index";
import {
  PageNavigation,
  RiskMedicalAnamnesis,
  PharmacologicalPlan,
  PatientHistory,
  PatientInformation,
  FtdEvaluation,
  FtdEvaluationReasonForm,
  RecordHistory,
} from "../../components";
import { PatientRequests } from "../../components/PatientRequests/PatientRequests";
import { IoMdAlert } from "react-icons/io";

export const PatientProfile = ({ ...props }) => {
  const { state, patient, error, requisitions, careType, getPatientData } =
    PatientProfileLogic();
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const pageTitle = location.pathname.includes("risk-medical-anamnesis")
    ? "Riziková léková anamnéza"
    : location.pathname.includes("pharmacological-plan")
    ? "Farmakologický plán"
    : location.pathname.includes("ftd-evaluation")
    ? "FTD hodnocení"
    : location.pathname.includes("record-history")
    ? "Historie záznamů"
    : location.pathname.includes("patient-information")
    ? "Informace o pacientovi"
    : location.pathname.includes("patient-history") && "Historie pacienta";

  return (
    <div>
      <h1>{pageTitle}</h1>
      <PageNavigation className="sub-page-navigation present">
        {careType === "hospitalization" && (
          <div>
            <NavLink
              to={`${url}/risk-medical-anamnesis/`}
              className={(isActive) => (!isActive ? "" : "active")}
            >
              Riziková léková anamnéza
            </NavLink>
          </div>
        )}
        <div>
          <NavLink
            to={`${url}/pharmacological-plan/`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Farmakologický plán
          </NavLink>
        </div>
        {requisitions.data.length !== 0 && (
          <div>
            <NavLink
              to={`${url}/requests/`}
              className={(isActive) => (!isActive ? "" : "active")}
            >
              Žádanky pacienta ({requisitions.data.length}){" "}
              {requisitions.answer && (
                <IoMdAlert style={{ color: "#A63A49", fontSize: 20 }} />
              )}
            </NavLink>
          </div>
        )}
        <div>
          <NavLink
            to={`${url}/ftd-evaluation/`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            FTD hodnocení
          </NavLink>
        </div>
        {patient?.patient?.cares.length > 1 && (
          <div>
            <NavLink
              to={`${url}/record-history/`}
              className={(isActive) => (!isActive ? "" : "active")}
            >
              Historie záznamů
            </NavLink>
          </div>
        )}
        <div>
          <NavLink
            to={`${url}/patient-information/`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Informace o pacientovi{" "}
          </NavLink>
        </div>
        <div>
          <NavLink
            to={`${url}/patient-history/`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Historie pacienta
          </NavLink>
        </div>
      </PageNavigation>
      {!state.loading && (
        <Switch>
          <Route
            path={`${path}/risk-medical-anamnesis/`}
            component={(props) => (
              <RiskMedicalAnamnesis
                patient={patient.patient}
                riskDrugHistory={patient.care.riskdrughistory}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                checkin={patient.care.checkin}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/pharmacological-plan/`}
            component={(props) => (
              <PharmacologicalPlan
                patient={patient.patient}
                plan={patient.care.pharmacologicalplan}
                care={patient.care}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                checkin={patient.care.checkin}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/requests/`}
            component={(props) => (
              <PatientRequests
                requisitions={requisitions.data}
                getPatientData={getPatientData}
                patient={patient.patient}
                plan={patient.care.pharmacologicalplan}
                care={patient.care}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                checkin={patient.care.checkin}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/ftd-evaluation/`}
            component={(props) => (
              <FtdEvaluation
                patient={patient.patient}
                ftdEvaluation={patient.care.pharmacological_evaluations}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                checkin={patient.care.checkin}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/record-history/`}
            component={(props) => (
              <RecordHistory
                patient={patient.patient}
                care={patient.care}
                checkin={patient.care.checkin}
                careIsLocked={patient.care.is_locked}
                careID={patient.care.id}
                plan={patient.care.pharmacologicalplan}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/ftd-evaluation-reason/`}
            component={(props) => (
              <FtdEvaluationReasonForm
                patient={patient.patient}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/patient-information/`}
            component={(props) => (
              <PatientInformation
                patient={patient.patient}
                careID={patient.care.id}
                careIsLocked={patient.care.is_locked}
                patientInformations={patient.care.patient_informations}
                {...props}
              />
            )}
          />
          <Route
            path={`${path}/patient-history/`}
            component={(props) => (
              <PatientHistory
                patient={patient.patient}
                careID={patient.care.id}
                {...props}
              />
            )}
          />
        </Switch>
      )}
      {error && <h2>{error.message}</h2>}
    </div>
  );
};
