import React, { useState, useEffect } from "react";
import { authHeader } from "../../auth/authHeader";
import { ClinicListItem } from "../index";
import { others } from "../../services/other.services";

export const SidebarLogic = () => {
  const [hide, setHide] = useState(true);
  const [logo, setLogo] = useState({
    full: null,
    small: null,
  });
  const [logoConceptica, setLogoConceptica] = useState(undefined);
  const [hospitalPlaces, setHospiatalPlaces] = useState({
    ambulances: [],
    hospitals: [],
  });
  const user = localStorage.getItem("user");
  const [links, setLinks] = useState([]);

  const onClick = () => {
    setHide(!hide);
  };

  useEffect(() => {
    if (user) {
      getMedicalPlaces();
    }

    if (!logo.full || !logo.small) {
      getLogo();
    }

    if (!logoConceptica) {
      getLogoConceptica();
    }
  }, [hide]);

  useEffect(() => {
    getLinks();
  }, []);

  const getMedicalPlaces = async (area) => {
    let hospitals = JSON.parse(localStorage.getItem("user")).hospitals;
    hospitals = hospitals.map((item) => (
      <ClinicListItem key={item.id} clinic={item} type="hospitalization" />
    ));

    let ambulances = JSON.parse(localStorage.getItem("user")).ambulances;
    ambulances = ambulances.map((item) => (
      <ClinicListItem key={item.id} clinic={item} type="ambulation" />
    ));
    setHospiatalPlaces({
      ...hospitalPlaces,
      hospitals: hospitals,
      ambulances: ambulances,
    });
  };

  const getLogo = async () => {
    const options = authHeader();
    fetch(process.env.REACT_APP_ROOT_API + `/files/${logoType}/`, {
      ...options,
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var img = URL.createObjectURL(blob);
        if (hide) {
          setLogo({ ...logo, small: img });
        } else {
          setLogo({ ...logo, full: img });
        }
      });
  };

  const getLogoConceptica = async () => {
    const options = authHeader();
    fetch(process.env.REACT_APP_ROOT_API + `/files/logo-conceptica/`, {
      ...options,
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var img = URL.createObjectURL(blob);
        setLogoConceptica(img);
      });
  };

  const getLinks = async () => {
    const response = await others.getLinks();
    if (response.status === 200) {
      setLinks(response.data.results.filter((item) => item.is_active));
    }
  };

  const logoType = hide ? "logo-small" : "logo-full";

  return { hide, onClick, hospitalPlaces, logo, logoConceptica, links };
};
