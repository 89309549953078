import { authHeader } from "../auth/authHeader";
import axios from "axios";

async function putMyClinics(data) {
  const options = authHeader("PUT");
  try {
    const response = await axios.put(
      process.env.REACT_APP_ROOT_API + "/user/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return error;
  }
}

async function getAllClinicsFilter(filter = "") {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/clinics/",
      { ...options, params: { clinic_filter: filter } }
    );
    return response.data.results;
  } catch (error) {
    return error;
  }
}

async function getDepartments(clinic) {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/departments/",
      { ...options, params: { clinic: clinic } }
    );
    return response.data.results;
  } catch (error) {
    return Promise.error;
  }
}

async function getAllClinics(clinicFilter) {
  const requestOptions = authHeader();
  const response = await fetch(
    `${process.env.REACT_APP_ROOT_API}/clinics/?clinic_filter=${clinicFilter}`,
    requestOptions
  );
  const response_1 = await handleResponse(response);
  return response_1.results;
}
async function getClinicById(id) {
  const requestOptions = authHeader();
  const response = await fetch(
    process.env.REACT_APP_ROOT_API + `/clinics/${id}/`,
    requestOptions
  );
  return await handleResponse(response);
}

async function getUserClinics() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const response = await fetch(
    process.env.REACT_APP_ROOT_API + `/user/`,
    requestOptions
  );
  return handleResponse(response);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export const clinicService = {
  getAllClinics,
  getClinicById,
  getUserClinics,
  getAllClinicsFilter,
  putMyClinics,
  getDepartments,
};
