import {useState, useEffect} from 'react' 
import { useForm } from 'react-hook-form'
import { helpFunc } from '../../helpers/helpFunction'
import { clinicService } from '../../services/clinic.services'
import { userService } from '../../services/user.services'

export const UserSettingsLogic = () => {
    const [state, setState] = useState({loading: true, submit: false, dataSaved: false})
    const [error, setError] = useState ({error: false})
    const [data, setData] = useState ({ambulances: [], hospitals: [], myClinics: []})

    //load user on component mount 
    useEffect (() => {
        getClinics ()
    }, [])
    
    //get clinics [ambulances, hospitals] from db 
    const getClinics = async () => {
        try {
            setState ({loading: true})
            //call all clinics for select options   
            let hospitals = await clinicService.getAllClinicsFilter ('hospitals')
            let ambulances = await clinicService.getAllClinicsFilter ('ambulances')
            hospitals = await helpFunc.pDataForSelect(hospitals,'clinics')
            ambulances = await helpFunc.pDataForSelect(ambulances,'clinics')
            setData ({...data, hospitals: hospitals, ambulances: ambulances})
            setState ({loading: false})
        } catch (error) {
            console.error(error)
            setError({error: true, message: 'Chyba při načítání klink'})
        }
    }

    //FORM SECTION

    const user = JSON.parse(localStorage.getItem("user"));

    const methods = useForm ({ 
        defaultValues: {
            hospital_0: {label: user.hospitals[0] ? user.hospitals[0].description : 'Vyberte kliniku...' , value: user.hospitals[0] ? user.hospitals[0].id : ""}, 
            hospital_1: {label: user.hospitals[1] ? user.hospitals[1].description : 'Vyberte kliniku...' , value: user.hospitals[1] ? user.hospitals[1].id : ""},
            hospital_2: {label: user.hospitals[2] ? user.hospitals[2].description : 'Vyberte kliniku...' , value: user.hospitals[2] ? user.hospitals[2].id : ""}, 
            hospital_3: {label: user.hospitals[3] ? user.hospitals[3].description : 'Vyberte kliniku...' , value: user.hospitals[3] ? user.hospitals[3].id : ""},
            hospital_4: {label: user.hospitals[4] ? user.hospitals[4].description : 'Vyberte kliniku...' , value: user.hospitals[4] ? user.hospitals[4].id : ""},
            hospital_5: {label: user.hospitals[5] ? user.hospitals[5].description : 'Vyberte kliniku...' , value: user.hospitals[5] ? user.hospitals[5].id : ""}, 
            ambulance_0: {label: user.ambulances[0] ? user.ambulances[0].description : 'Vyberte ambulanci...' , value: user.ambulances[0] ? user.ambulances[0].id : ""},  
            ambulance_1: {label: user.ambulances[1] ? user.ambulances[1].description : 'Vyberte ambulanci...' , value: user.ambulances[1] ? user.ambulances[1].id : ""},
            ambulance_2:  {label: user.ambulances[2] ? user.ambulances[2].description : 'Vyberte ambulanci...' , value: user.ambulances[2] ? user.ambulances[2].id : ""}, 
            ambulance_3: {label: user.ambulances[3] ? user.ambulances[3].description : 'Vyberte ambulanci...' , value: user.ambulances[3] ? user.ambulances[3].id : ""},
            ambulance_4:{label: user.ambulances[4] ? user.ambulances[4].description : 'Vyberte ambulanci...' , value: user.ambulances[4] ? user.ambulances[4].id : ""},
            ambulance_5: {label: user.ambulances[5] ? user.ambulances[5].description : 'Vyberte ambulanci...' , value: user.ambulances[5] ? user.ambulances[5].id : ""},  
        } 
    })

    //saving data 
    const saveData = async (data, e) => {
        setState ({...state, submit: false, dataSaved: false})
        e.preventDefault()
        let sData = {hospitals:[], ambulances: []}
        let hNames = ['hospital_0', 'hospital_1', 'hospital_2', 'hospital_3', 'hospital_4', 'hospital_5']
        for (const clinic in data) {
            if (data[clinic] === null || data[clinic].value === "" || data[clinic].value === null || data[clinic].value === undefined) {
                continue;
            }
            if (hNames.includes(clinic)) {
                sData.hospitals.push(data[clinic].value)
            }
            else {
                sData.ambulances.push(data[clinic].value)
            }
        }
        try {
            setState ({...state, submit: true, dataSaved: false})
            const response = await userService.patchUser(sData)
            if (response.status === 200) {
                const myHopsitals = await clinicService.getAllClinicsFilter('my_hospitals')
                const myAmbulances = await clinicService.getAllClinicsFilter('my_ambulances')
                let user = JSON.parse(localStorage.getItem("user"));
                localStorage.setItem("user", JSON.stringify({...user, hospitals: myHopsitals, ambulances: myAmbulances}));
            }
            
            setState ({...state, submit: false, dataSaved: true})
        } catch (error) {
            console.error(error)
            setError({error: true, message: 'Chyba při ukládání dat'})
        }
    }


    return {state, error, data, methods, saveData}
} 