import React, { useState, useEffect } from "react";
import { clinicService } from "../../services/clinic.services";
import { ClinicBoxItem } from "../../components";
import { helpFunc } from "../../helpers/helpFunction";

export const AllClinicsLogic = (careType) => {
  const [state, setState] = useState({ loading: true, dataLoaded: false });
  const [error, setError] = useState({ error: false, message: "" });
  const [clinics, setClinics] = useState("");
  const [clinicsList, setClinicsList] = useState("");
  const [totalPatientsCount, setTotalPatientsCount] = useState(0);

  const getClinicFilter =
    careType === "hospitalization" ? "hospitals" : "ambulances";

  const fetchData = async () => {
    try {
      const clinics = await clinicService.getAllClinics(getClinicFilter);
      setClinics(clinics);
      setState({ ...state, dataLoaded: true, loading: false });
    } catch (error) {
      setState({ ...state, loading: false, dataLoaded: false });
      setError({ error: true, message: "Problém s načítáním dat." });
    }
  };

  const getClinicList = () => {
    let clinicsList = helpFunc.sortClinics(clinics);
    let noCheckInCount = 0;
    let totalPatients = 0;
    clinicsList = clinics.map((clinic) => {
      if (careType === "ambulation") {
        noCheckInCount = clinic.ambulation_patient_without_checkin_count;
      } else {
        noCheckInCount = clinic.hospitalization_patient_without_checkin_count;
        totalPatients += clinic.hospitalization_patient_without_checkin_count;
      }

      setTotalPatientsCount(totalPatients);
      return (
        <div className="item" key={clinic.id}>
          <ClinicBoxItem
            id={clinic.id}
            description={clinic.description}
            abbreviation={clinic.abbreviation}
            noCheckInCount={noCheckInCount}
            img={clinic.image}
            careType={careType}
          ></ClinicBoxItem>
        </div>
      );
    });
    setClinicsList(clinicsList);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setState({ ...state, loading: true, dataLoaded: false });
    };
  }, []);

  useEffect(() => {
    if (state.dataLoaded) {
      getClinicList();
    }
  }, [state.dataLoaded, careType]);

  const loading = state.loading;
  return { loading, error, clinicsList, totalPatientsCount };
};
