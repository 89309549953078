import { helpFunc } from "../../helpers/helpFunction";

export const PopUpPatienEntryControl = (props) => {
  const { cares, onClose, onSubmit } = props;
  return (
    <div className="modal flex-container-column">
      <div className="modal_content">
        <div className="text">
          <p className="strong">
            Byly nalezeny předchozí vstupní kontroly pacienta:
          </p>
        </div>
        <div className="list">
          {cares.map((item) => (
            <p
              className="modal-content-list"
              key={item.id}
              onClick={() => onSubmit(item.checkin)}
            >
              Vstupní kontrola ze dne: {helpFunc.formatDate(item.checkin.updated_at)}
            </p>
          ))}
        </div>
        <div className="btn-row">
          <button type="button" className="button" onClick={onClose}>
            Zavřít
          </button>
        </div>
      </div>
    </div>
  );
};
