import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BiSortAlt2 } from "react-icons/bi";
import { patientService } from "../../services/patient.services";
import { helpFunc } from "../../helpers/helpFunction";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";

const birthNumber = [
  "birth_number",
  "birth_number__contains",
  "birth_number__startswith",
  "birth_number__endswith",
];

const externalId = [
  "external_id",
  "external_id__contains",
  "external_id__endswith",
  "external_id__startswith",
];

const patientName = [
  "name",
  "name__contains",
  "name__startswith",
  "name__endswith",
];

const age = ["age_display", "age_display__max", "age_display__min"];

const diagnosisName = [
  "current_care_diagnosis_name",
  "current_care_diagnosis_name__contains",
  "current_care_diagnosis_name__startswith",
  "current_care_diagnosis_name__endswith",
];

const clinic = [
  "current_care_last_dekurz_clinic_description",
  "current_care_last_dekurz_clinic_description__contains",
  "current_care_last_dekurz_clinic_description__endswith",
  "current_care_last_dekurz_clinic_description__startswith",
];

const departmentDescription = [
  "current_care_department_description",
  "current_care_department_description__contains",
  "current_care_department_description__endswith",
  "current_care_department_description__startswith",
];

const careDays = [
  "current_care_days",
  "current_care_days__max",
  "current_care_days__min",
];

const lastDekurz = [
  "current_care_last_dekurz_date",
  "current_care_last_dekurz_date__max",
  "current_care_last_dekurz_date__min",
];

const careStartedAt = [
  "current_care_started_at",
  "current_care_started_at__max",
  "current_care_started_at__min",
];

const doctorName = [
  "current_care_doctor_name",
  "current_care_doctor_name__contains",
  "current_care_doctor_name__endswith",
  "current_care_doctor_name__startswith",
];

const category = ["current_care_category"];

const defaultFilter = {
  ...externalId.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...birthNumber.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...age.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...diagnosisName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...clinic.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...departmentDescription.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...careDays.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...lastDekurz.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...careStartedAt.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...doctorName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...category.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
};

export const AllPatientsListLogic = ({ careType }) => {
  const [data, setData] = useState({ data: [], pagination: { count: 0 } });
  const [error, setError] = useState({ error: false });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    is_active: true,
    current_care_type: careType,
    offset: 0,
    page: 1,
    limit: PAGINATION_PER_PAGE_LIMIT,
    ordering: "-current_care_days",
  });

  const storageFilter = JSON.parse(localStorage.getItem("all-patients-filter"));

  const [filter, setFilter] = useState(
    storageFilter ? storageFilter : defaultFilter
  );

  const history = useHistory();

  useEffect(() => {
    getAllPatients();
  }, [careType]);

  useEffect(() => {
    localStorage.setItem("all-patients-filter", JSON.stringify(filter));
  }, [filter]);

  const handleDateChange = (event, field) => {
    resetTableFilterValues(field);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [field]: event,
    }));
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    resetTableFilterValues(name);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleResetFilter = () => {
    localStorage.removeItem("all-patients-filter");
    localStorage.removeItem("all-patients-ordering");
    window.location.reload(false);
  };

  const getAllPatients = async (
    offset = params.offset,
    page = params.page,
    o = params.ordering
  ) => {
    setLoading(true);

    const data = { ...filter };

    const response = await await patientService.getPatientsFilter({
      ...params,
      page,
      offset,
      ordering: o,
      ...filterMapper(data),
    });

    if (response.results.length === 0) {
      setData({
        data: [],
        pagination: { count: response.count },
      });
      setError({ error: true, message: "Seznam žádanek je prázdny." });
      setLoading(false);
    }

    if (response.results.length !== 0) {
      setData({
        data: await parseData(response.results),
        pagination: { count: response.count },
      });
      setParams({
        ...params,
        page: response.page,
        offset: response.offset,
        ordering: o,
      });
      setLoading(false);
    }
  };

  const parseData = async (data) => {
    return Promise.all(
      data.map(async (patient) => {
        let activeCare = helpFunc.getActiveCare(patient.cares);
        activeCare = helpFunc.replaceNull(activeCare[0]);
        return {
          ...patient,
          cares: [activeCare],
          id: patient?.id,
          external_id: patient?.external_id,
          age: helpFunc.countPatientAge(
            patient?.age_display,
            patient.age_display_measure_unit
          ),
          diagnoses: activeCare?.main_diagnosis.name,
          days_in_hospital: patient?.current_care_days,
          last_dekurz: helpFunc.formatDate(activeCare?.last_dekurz?.made_at),
          last_dekurz_timestamp: new Date(
            activeCare?.last_dekurz?.made_at
          ).getTime(),
          doctor_name:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.name || "Žádne informace"
              : activeCare?.last_dekurz?.doctor?.name || "Žádne informace",
          f_title:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.f_title || ""
              : activeCare?.last_dekurz?.doctor?.f_title || "",
          l_title:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.l_title || ""
              : activeCare?.last_dekurz?.doctor?.l_title || "",
          department: patient.current_care_last_dekurz_department_description,
          category: activeCare?.category
            ? activeCare?.category.name
            : "Žádne informace",
          first_visit: helpFunc.formatDate(activeCare?.started_at),
          requisitions: activeCare.requisitions,
          planned_hospitalization: activeCare.planned_hospitalization,
          clinic: patient?.current_care_last_dekurz_clinic_description,
        };
      })
    );
  };

  const resetTableFilterValues = (name) => {
    if (birthNumber.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        birth_number: undefined,
        birth_number__contains: undefined,
        birth_number__startswith: undefined,
        birth_number__endswith: undefined,
      }));
    }

    if (externalId.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        external_id: undefined,
        external_id__contains: undefined,
        external_id__endswith: undefined,
        external_id__startswith: undefined,
      }));
    }

    if (patientName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        name: undefined,
        name__contains: undefined,
        name__startswith: undefined,
        name__endswith: undefined,
      }));
    }

    if (age.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        age_display: undefined,
        age_display__max: undefined,
        age_display__min: undefined,
      }));
    }

    if (diagnosisName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_diagnosis_name: undefined,
        current_care_diagnosis_name__contains: undefined,
        current_care_diagnosis_name__startswith: undefined,
        current_care_diagnosis_name__endswith: undefined,
      }));
    }

    if (clinic.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_last_dekurz_clinic_description: undefined,
        current_care_last_dekurz_clinic_description__contains: undefined,
        current_care_last_dekurz_clinic_description__endswith: undefined,
        current_care_last_dekurz_clinic_description__startswith: undefined,
      }));
    }

    if (departmentDescription.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_department_description: undefined,
        current_care_department_description__contains: undefined,
        current_care_department_description__endswith: undefined,
        current_care_department_description__startswith: undefined,
      }));
    }

    if (careDays.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_days: undefined,
        current_care_days__max: undefined,
        current_care_days__min: undefined,
      }));
    }

    if (lastDekurz.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_last_dekurz_date: undefined,
        current_care_last_dekurz_date__max: undefined,
        current_care_last_dekurz_date__min: undefined,
      }));
    }

    if (careStartedAt.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_started_at: undefined,
        current_care_started_at__max: undefined,
        current_care_started_at__min: undefined,
      }));
    }

    if (doctorName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_doctor_name: undefined,
        current_care_doctor_name__contains: undefined,
        current_care_doctor_name__endswith: undefined,
        current_care_doctor_name__startswith: undefined,
      }));
    }

    if (category.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_category: undefined,
        current_care_category__contains: undefined,
        current_care_category__endswith: undefined,
        current_care_category__startswith: undefined,
      }));
    }
  };

  const handleTableSort = (orderBy) => {
    getAllPatients(params.offset, params.page, getOrderingValue(orderBy));
  };

  const getOrderingValue = (value) => {
    if (value === params.ordering) {
      const orderingValue = value.includes("-") ? value : `-${value}`;
      localStorage.setItem("all-patients-ordering", orderingValue);
      return orderingValue;
    }
    localStorage.setItem("all-patients-ordering", value);
    return value;
  };

  const columns = [];
  columns["hospitalization"] = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age_display")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>DG</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_diagnosis_name")}
            />
          </span>
        </>
      ),
      accessor: "diagnoses",
    },
    {
      Header: () => (
        <>
          <span>Klinika</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort("current_care_last_dekurz_clinic_description")
              }
            />
          </span>
        </>
      ),
      accessor: "clinic",
    },
    {
      Header: () => (
        <>
          <span>Oddělení</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort(
                  "current_care_last_dekurz_department_description"
                )
              }
            />
          </span>
        </>
      ),
      accessor: "department",
    },
    {
      Header: () => (
        <>
          <span>Počet dnů hospitalizace</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_days")}
            />
          </span>
        </>
      ),
      accessor: "days_in_hospital",
    },
    {
      Header: () => (
        <>
          <span>Datum pos. dekurzu</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_last_dekurz_date")}
            />
          </span>
        </>
      ),
      accessor: "last_dekurz_timestamp",
      Cell: ({ row }) => row.original.last_dekurz,
    },
    {
      Header: () => (
        <>
          <span>Jméno lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
      Cell: ({ row }) =>
        `${row.original?.f_title} ${row.original?.doctor_name} ${row.original?.l_title}`,
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button desktop-visible"
            onClick={() => openPatient(row)}
          >
            Otevřít pacienta
          </button>
          <i
            style={{ color: "#ffffff" }}
            className="fas fa-user-injured mobile-visible mr-2 table-icon"
            onClick={() => openPatient(row)}
          ></i>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
            style={{ color: "#ffffff" }}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },
    { Header: "", accessor: "more_information" },
    { Header: "Status", accessor: "status" },
  ]);

  columns["ambulation"] = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>DG</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_diagnosis_name")}
            />
          </span>
        </>
      ),
      accessor: "diagnoses",
    },
    {
      Header: () => (
        <>
          <span>Klinika</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("care__clinic__description")}
            />
          </span>
        </>
      ),
      accessor: "clinic",
    },
    {
      Header: () => (
        <>
          <span>Oddělení</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort(
                  "current_care_last_dekurz_department_description"
                )
              }
            />
          </span>
        </>
      ),
      accessor: "department",
    },

    {
      Header: () => (
        <>
          <span>Datum návštěvy ambulance</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_started_at")}
            />
          </span>
        </>
      ),
      accessor: "first_visit",
    },
    {
      Header: () => (
        <>
          <span>Jméno lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
      Cell: ({ row }) =>
        `${row.original?.f_title} ${row.original?.doctor_name} ${row.original?.l_title}`,
    },
    {
      Header: () => (
        <>
          <span>Kategorie</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_category")}
            />
          </span>
        </>
      ),
      accessor: "category",
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button desktop-visible"
            onClick={() => openPatient(row)}
          >
            Otevřít pacienta
          </button>
          <i
            style={{ color: "#ffffff" }}
            className="fas fa-user-injured mobile-visible mr-2 table-icon"
            onClick={() => openPatient(row)}
          ></i>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
            style={{ color: "#ffffff" }}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },
    { Header: "", accessor: "more_information" },
    { Header: "Status", accessor: "status" },
  ]);

  const openPatient = (row) => {
    row.original.cares[0].checkin !== ""
      ? history.push(
          `/patient-profile/${row.original.id}/care/${row.original.cares[0].id}/pharmacological-plan`
        )
      : history.push(
          `/patient-entry-control/${row.original.id}/care/${row.original.cares[0].id}`
        );
  };

  const togglePopup = () => {
    setError({ error: false });
  };

  return {
    columns,
    data,
    loading,
    params,
    filter,
    error,
    togglePopup,
    getAllPatients,
    handleResetFilter,
    handleFilterChange,
    handleDateChange,
  };
};

const filterMapper = (obj) => {
  const maped = obj;
  for (const key in maped) {
    if (maped[key] === undefined || maped[key] === "" || maped[key] === null) {
      delete maped[key];
    }
  }

  if (maped.current_care_last_dekurz_date)
    maped.current_care_last_dekurz_date = helpFunc.parseDate(
      maped.current_care_last_dekurz_date
    );

  if (maped.current_care_last_dekurz_date__max)
    maped.current_care_last_dekurz_date__max = helpFunc.parseDate(
      maped.current_care_last_dekurz_date__max
    );

  if (maped.current_care_last_dekurz_date__min)
    maped.current_care_last_dekurz_date__min = helpFunc.parseDate(
      maped.current_care_last_dekurz_date__min
    );

  if (maped.current_care_started_at)
    maped.current_care_started_at = helpFunc.parseDate(
      maped.current_care_started_at
    );

  if (maped.current_care_started_at__max)
    maped.current_care_started_at__max = helpFunc.parseDate(
      maped.current_care_started_at__max
    );

  if (maped.current_care_started_at__min)
    maped.current_care_started_at__min = helpFunc.parseDate(
      maped.current_care_started_at__min
    );
  return maped;
};
