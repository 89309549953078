import DOMPurify from "dompurify";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";
import { saveAs } from "file-saver";
import { others } from "../../services/other.services";

export const TextContainerLogic = (
  rawData = null,
  name = "",
  id = null,
  type = "",
  onDeletePlan,
  handleReloadProcedures,
  hide = true
) => {
  const [state, setState] = useState({
    active: false,
    hide,
    submit: false,
    saved: false,
    deleting: false,
    delete: false,
  });
  const [data, setData] = useState({
    text: null,
    user: null,
    date: null,
    type: null,
  });
  const [copy, setCopy] = useState({ copy: false, value: "" });
  const [error, setError] = useState({ error: false });

  const planId = localStorage.getItem("planId");

  //parse data on compoennet mount
  useEffect(() => {
    pData(rawData);
  }, []);

  //parse data for text container
  const pData = (data) => {
    setData({
      text: data.text,
      textShort: helpFunc.cutText(data.text ?? "", 500),
      plainText: helpFunc.removeHiddenChar(helpFunc.removeHTMLTags(data.text)),
      type: getTextType(type, data.evaluation_count),
      author: helpFunc.getAuthor(data.author),
      date: helpFunc.formatDateWithTime(data.created_at),
      evaluation_count: data.evaluation_count,
      clinic: data.clinic || null,
    });
  };

  const options = {
    planEvaluation: [
      { name: "1", label: "Zhodnocení 1x" },
      { name: "2", label: "Zhodnocení 2x" },
      { name: "3", label: "Zhodnocení 3x" },
      { name: "4", label: "Zhodnocení 4x" },
    ],
  };

  //convert hmtl tags to formated text in a save way
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  //FORM SECTION
  const formMethods = useForm({
    defaultValues: {
      [name]: "",
      evaluation_count: {
        name: "",
        label: "Vyberte...",
      },
    },
  });

  //get type of text passed to containser
  const getTextType = (type = null, evaluationCount = null) => {
    switch (type) {
      case "plan":
        type = "Plán farmakoterapie";
        break;
      case "verification":
        type = "Aktualizace plánu";
        break;
      case "recommendation":
        type = "Farmakoterap. doporučení";
        break;
      case "recommendationDischarge":
        type = "Farm. dop. do propouštěcí zprávy";
        break;
      case "evaluation":
        type = `Zhodnocení ${evaluationCount}x`;
        break;
    }
    return type;
  };

  //toggle more infor for text container
  const toggleMoreInfo = () => {
    setState({ ...state, hide: !state.hide });
  };

  //click text to clipboard on floppy disk click
  const onClickCopy = (text) => {
    setCopy({ copy: true, value: text });
    setTimeout(() => {
      setCopy({ ...copy, copy: false });
    }, 1000);
  };

  //Save users data -> Only patch becuase it will only editing
  const saveData = (data) => {
    clearError();
    switch (type) {
      case "verification":
        saveVerification(data);
        break;
      case "plan":
        savePlan(data);
        break;
      case "recommendation":
        saveRecommendation(data);
        break;
      case "recommendationDischarge":
        saveRecommendationRelase(data);
        break;
      case "evaluation":
        savePlanEvaluation(data);
        break;
    }
  };

  //Save verification
  const saveVerification = async (data) => {
    setState({ ...state, submit: true, saved: false });
    try {
      let response = await patientService.patchPlanVerifications(data, id);
      setState({ ...state, submit: false, active: false, saved: true });
      pData(response);
      timer(3000, setState);
    } catch (e) {
      raiseError(e, "Chyba při ukládání aktualizace plánu");
      setState({ ...state, submit: false });
    }
  };

  //Save plan
  const savePlan = async (data) => {
    setState({ ...state, submit: true, saved: false });
    try {
      let response = await patientService.patchPlanTexts(data, id);
      setState({ ...state, submit: false, active: false, saved: true });
      pData(response);
      timer(3000, setState);
    } catch (e) {
      raiseError(e, "Chyba při ukládání Farmakoterapeutického plánu");
      setState({ ...state, submit: false });
    }
  };

  //Save recommendataion
  const saveRecommendation = async (data) => {
    setState({ ...state, submit: true, saved: false });
    try {
      let response = await patientService.patchRecommendation(data, id);
      setState({ ...state, submit: false, active: false, saved: true });
      pData(response.data);
      timer(3000, setState);
    } catch (e) {
      raiseError(e, "Chyba při ukládání Farmakoterapeutického doporučení");
      setState({ ...state, submit: false });
    }
  };

  const savePlanEvaluation = async (data) => {
    setState({ ...state, submit: true, saved: false });
    try {
      data = {
        ...data,
        pharmacological_plan: rawData.pharmacological_plan,
        evaluation_count: data.evaluation_count.name,
      };
      let response = await patientService.patchPlanEvaluation(data, id);
      setState({ ...state, submit: false, active: false, saved: true });
      pData(response.data);
      timer(3000, setState);
    } catch (e) {
      raiseError(e, "Chyba při ukládání Konziliárního zhodnocení");
      setState({ ...state, submit: false });
    }
  };

  //Save recommendation for realase text
  const saveRecommendationRelase = async (data) => {
    setState({ ...state, submit: true, saved: false });
    try {
      const response = await patientService.patchRecommendationsDischarge(
        data,
        id
      );
      setState({ ...state, submit: false, active: false, saved: true });
      pData(response.data);
      timer(3000, setState);
    } catch (e) {
      raiseError(
        e,
        "Chyba při ukládání Farmakoterapeutického doporučení do propoustěcí zprávy"
      );
      setState({ ...state, submit: false });
    }
  };

  //Delete container data
  const deleteData = () => {
    clearError();
    switch (type) {
      case "verification":
        deleteVerification(id);
        break;
      case "plan":
        deletePlan(id);
        break;
      case "recommendation":
        deleteRecommendation(id);
        break;
      case "recommendationDischarge":
        deleteRecommendationRelase(id);
        break;
    }
  };
  //Delete delete plan verifications
  const deleteVerification = async () => {
    handleReloadProcedures(false);
    setState({ ...state, delete: false, deleting: true });
    try {
      await patientService.deletePlanVerifications(id);
      setState({ ...state, deleting: false, delete: true });
      handleReloadProcedures(true);
    } catch (e) {
      raiseError(e, "Záznam se nepodařilo smazat");
      setState({ ...state, deleting: false });
    }
  };

  //Delete delete plan texts
  const deletePlan = async () => {
    setState({ ...state, delete: false, deleting: true });
    handleReloadProcedures(false);
    try {
      const response = await patientService.getPharmaPlan(planId);
      if (response.verifications.length !== 0) {
        raiseError(
          true,
          "Před odstraněním plánu farmakoterapie je třeba odstranit navázané aktualizace plánu."
        );
        setState({ ...state, deleting: false, delete: false });
        return;
      }
      await patientService.deletePlanTexts(id);
      setState({ ...state, deleting: false, delete: true });
      onDeletePlan();
      handleReloadProcedures(true);
    } catch (e) {
      raiseError(e, "Záznam se nepodařilo smazat");
      setState({ ...state, deleting: false });
    }
  };

  //Delete plan recommendations
  const deleteRecommendation = async () => {
    setState({ ...state, delete: false, deleting: true });
    handleReloadProcedures(false);
    try {
      await patientService.deleteRecommendation(id);
      setState({ ...state, deleting: false, delete: true });
      handleReloadProcedures(true);
    } catch (e) {
      raiseError(e, "Záznam se nepodařilo smazat");
      setState({ ...state, deleting: false });
    }
  };

  //Delete recommendation for realase text
  const deleteRecommendationRelase = async () => {
    setState({ ...state, delete: false, deleting: true });
    handleReloadProcedures(false);
    try {
      await patientService.deleteRecommendationsDischarge(id);
      setState({ ...state, deleting: false, delete: true });
      handleReloadProcedures(true);
    } catch (e) {
      raiseError(e, "Záznam se nepodařilo smazat");
      setState({ ...state, deleting: false });
    }
  };

  //user click button
  const activateField = (e) => {
    e.preventDefault();
    formMethods.setValue("text", data.text);
    formMethods.setValue("evaluation_count", {
      name: data.evaluation_count?.toString(),
      label: `Zhodnocení ${data.evaluation_count}x`,
    });
    setState({ ...state, active: true });
  };

  const print = () => {
    switch (type) {
      case "recommendation":
        printRecommendation("standard");
        break;
      case "recommendationDischarge":
        printDicharge("discharge");
        break;
      case "evaluation":
        printEvaluation();
        break;
    }
  };

  //function to print an recommendations discharge
  const printRecommendation = async (type) => {
    await others.printRecommendation(id, type).then((report) => {
      saveAs(
        process.env.REACT_APP_ROOT_FILES + report.file,
        `Doporučení k farmakologickému plánu`
      );
    });
  };

  //function to print an recommendations discharge
  const printDicharge = async (type) => {
    await others.printRecommendation(id, type).then((report) => {
      saveAs(
        process.env.REACT_APP_ROOT_FILES + report.file,
        `Propouštěcí zpráva`
      );
    });
  };

  const printEvaluation = async () => {
    await others.printPlanEvaluation(id).then((report) => {
      saveAs(process.env.REACT_APP_ROOT_FILES + report.file, `Zhodnocení`);
    });
  };

  const timer = (time, dispatch) => {
    setTimeout(() => {
      dispatch((prevState) => ({ ...prevState, saved: false }));
    }, time);
  };

  //clear form errors
  const clearError = () => {
    setError({ error: false });
  };

  //set error
  const raiseError = (e, message) => {
    console.error(e);
    setError({ error: true, message: message });
  };

  return {
    state,
    data,
    copy,
    formMethods,
    error,
    options,
    clearError,
    createMarkup,
    saveData,
    activateField,
    toggleMoreInfo,
    onClickCopy,
    print,
    deleteData,
  };
};
